import Lottie from 'react-lottie-player';

import { Box, FadeIn, Paragraph } from '@hl-portals/ui';

import lottieJson from '../../../public/animations/lottie-calculator-loading.json';

export const Loading = (props) => (
  <FadeIn
    width="100%"
    height="calc(100vh - 80px)"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <Box width="120px">
      <Lottie loop animationData={lottieJson} play />
    </Box>
    <Paragraph variant="heading-4">
      Estimating equity unlock amount...
    </Paragraph>
    <br />
    <Paragraph>Please don't close the window.</Paragraph>
  </FadeIn>
);
