import { PropertyEligibilityChecks } from '../domain/property-eligibility-checks';

export const PROPERTY_ELIGIBILITY_CHECKS_LABELS: Record<
  keyof PropertyEligibilityChecks,
  string
> = {
  listing_period_check:
    'The property has been listed for more than 100 days in the past 365 days.',
  lot_size_check: "The property's lot size is more than 2 acres.",
  property_type_check:
    'The property is not a single-family home, townhome, or condo.',
  room_count_check:
    'The property does not have at least one bedroom and one bathroom.',
  sq_footage_check:
    "The property's gross living area is less than 750 square feet.",
  zoning_description_check: 'The property is not zoned for residential',
  property_value_check:
    'The property value is estimated to be more than $2,500,000.',
};
