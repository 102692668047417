import * as Sentry from '@sentry/nextjs';

import { UseQueryOptions, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import {
  GET_PROPERTY_UUID,
  GET_PROPERTY_UUID_PUBLIC,
} from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

type PropertyUUIDResponse = {
  uuid: string;
};

export const USE_PROPERTY_UUID_QUERY_KEY = 'USE_PROPERTY_UUID_QUERY_KEY';

export const usePropertyUUID = (
  fullAddress: string,
  options?: UseQueryOptions<string> & {
    isPublic?: boolean;
  }
) => {
  const encodedAddress = encodeURIComponent(fullAddress);
  const propertyUUIDUrl = GET_PROPERTY_UUID(encodedAddress);
  const propertyUUIDPublicUrl = GET_PROPERTY_UUID_PUBLIC(encodedAddress);

  return useQuery<string>(
    [USE_PROPERTY_UUID_QUERY_KEY, fullAddress],
    async ({ signal }) => {
      const { uuid } = await enhancedAxios<PropertyUUIDResponse>({
        url: options?.isPublic ? propertyUUIDPublicUrl : propertyUUIDUrl,
        translateJsona: true,
        signal,
      });
      return uuid;
    },
    {
      ...options,
      onError: (error) => {
        Sentry.captureException(error);
        toast('There was an error', { type: 'error' });

        if (options?.onError) {
          options.onError(error);
        }
      },
    }
  );
};
